<!-- @Author: Yu_Bo -->
<template>
  <div class='painting_details'>
    <div class="details_info">
      <div class="info_title">
        <div class="title">
          <div class="img">
            <el-image style="width: 100%; height: 100%" :src="userInfo.headPic || circleUrl" fit="cover"></el-image>
          </div>
          <div class="name">{{ userInfo.nickname }}</div>
        </div>
        <div class="right">
          <el-button size="mini" type="primary" @click="toCreate">再来一张</el-button>
          <el-button size="mini" type="primary" @click="downloadFile(info.production_file, info.name)">下载</el-button>
        </div>
      </div>
      <div class="info_tip">
        <div class="text">创作时间：{{ info.create_time }}</div>
        <div class="text">画面比例：{{ info.proportion }}</div>
        <div class="text">参考图：<span @click="imageBtn">查看</span>（相似度{{ info.reference_rate }}%）</div>
      </div>
    </div>
    <!--  -->
    <div class="details_img">
      <div class="title">漫画展示</div>
      <div class="img_list" style="padding-right: 86px;">
        <swiper-list :list="imgList"></swiper-list>
      </div>
    </div>
    <!-- 查看图片 -->
    <image-amplify ref="image"></image-amplify>
  </div>
</template>

<script>
import { mapState } from "vuex"
import SwiperList from '@/components/swiper_list/swiper_list.vue'
import ImageAmplify from '@/components/swiper_list/image_amplify.vue'
export default {
  components: {
    SwiperList,
    ImageAmplify
  },
  props: {},
  data() {
    return {
      circleUrl: require('@/assets/images/img/header.png'),
      id: 0,
      info: {},
      imgList: []
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo.user_info,
    }),
  },
  watch: {},
  created() {
    this.id = this.$route.params.id
    if (this.id) {
      this.getDetails()
    }
    // 详情页导航
    var name = this.$route.query.name || `漫画${this.$route.params.id}`
    var key = this.$route.meta.key + '_' + this.$route.params.id
    this.$store.dispatch('tabsArr/getName', { name, key })
  },
  mounted() {

  },
  methods: {

    // 查看
    imageBtn() {
      this.$refs.image.openDialog(this.info.reference_pic)
    },
    getDetails() {
      this.$creationApi.getCartoonDetails(this.id).then(res => {
        if (res.code == 1000) {
          this.info = res.result
          if (res.result?.production_file?.length) {
            res.result.production_file.forEach(item => {
              let obj = {
                img: item,
              }
              this.imgList.push(obj)
            })
          }
        } else {
          this.$errMsg(res.message)
        }
      })
    },
    // 下载 多张
    downloadFile(val, name) {
      if (val && val.length) {
        for (let i = 0; i < val.length; i++) {
          setTimeout(() => {
            this.downloadMany(val[i], `${name}${Date.now()}`)
          }, i * 1000)

        }
      }
    },
    downloadMany(url, name) {
      const a = document.createElement('a');
      // 解决图片跨域
      a.href = `${url}?response-content-type=application/octet-stream`;
      a.download = name || '';
      a.click();
      a.remove();
    },
    toCreate() {
      let path = '/workben/comics'
      this.$router.push({
        path: path,
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.painting_details {
  width: 100%;

  .details_info {
    width: 100%;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px;

    .info_title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;

        .img {
          width: 30px;
          height: 30px;
        }

        .name {
          padding-left: 10px;
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }

      .right {
        display: flex;

        .el-button {
          margin-left: 6px;
        }
      }
    }

    .info_text {
      padding-top: 10px;
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 24px;

      span {
        cursor: pointer;
        color: #2E4BF2;
        margin-left: 20px;
      }
    }

    .info_tip {
      width: 50%;
      padding-top: 10px;
      display: flex;
      align-items: center;

      .text {
        width: 33.33%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;

        span {
          cursor: pointer;
          color: #2E4BF2;
        }
      }
    }
  }

  /*  */
  .details_img {
    width: 100%;
    margin-top: 20px;
    padding: 30px;
    background: #FFFFFF;
    border-radius: 10px;

    .img_list {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
